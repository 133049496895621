<template>
  <v-card class="my-4" elevation="4">
    <!-- ToDo: refactor -->
    <v-card-title><v-icon class="mr-2">mdi-cash-multiple</v-icon> Pontos</v-card-title>
    <v-card-text class="mx-2 py-0">
      <v-row no-gutters>
        <v-col cols="6">
          <tooltip-switch class-props="mt-1" :editable="true" :bottom="true" :value.sync="points.active" label="PROGRAMA DE PONTOS" :tip="pointsTip" />
        </v-col>
        <v-col cols="6">
          <tooltip-switch class-props="mt-1" :editable="true" :bottom="true" :value.sync="btns.points" label="BOTÕES NA HOME" :tip="btnsTip" />
        </v-col>
      </v-row>
      <div v-if="points.active">
        <v-card-title class="px-0">Gerais</v-card-title>
        <v-row no-gutters>
          <div>
            <span class="pb-2">Duração dos pontos</span>
            <v-select v-model="points.duration.scale" class="px-0 mr-4" :items="pointsDurationScale" outlined hide-details />
          </div>
          <div>
            <span class="pb-2">Quantidade</span>
            <v-text-field v-model="points.duration.amount" class="px-0 mr-4" type="number" outlined hide-details value />
          </div>
          <div>
            <span class="pb-2">Rate</span>
            <v-text-field v-model="points.rate" class="px-0 mr-4" outlined suffix="pontos/R$" />
          </div>
          <div>
            <span class="pb-2">Início do programa</span>
            <date-picker v-model="points.start_date" class-props="px-0 mr-4" :outline="true" :date.sync="points.start_date" />
          </div>
          <div>
            <span class="pb-2">Final do programa</span>
            <date-picker v-model="points.end_date" class-props="px-0 mr-4" :outline="true" :date.sync="points.end_date" />
          </div>
          <div>
            <span class="pb-2">Última data de computação dos pts</span>
            <v-text-field v-model="points.last_points_computation_at" full-width style="width: 280px;" class="px-0 mr-4" outlined disabled />
          </div>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions class="pl-0">
      <v-spacer />
      <v-btn color="primary" :disabled="loading" @click="dialog = true"><v-icon class="mr-1">sync</v-icon> Atualizar Programa de Pontos</v-btn>
    </v-card-actions>

    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @proceed="updatePoints" @cancel="closeDialog" />
  </v-card>
</template>
<script>
import { MUTATION_UPDATE_APP_POINTS, QUERY_CONFIGURATION, QUERY_HOME_BUTTONS } from '@/modules/accounts/graphql'

export default {
  components: {
    TooltipSwitch: () => import('@/components/base/TooltipSwitch'),
    DatePicker: () => import('@/components/calendar/DatePicker'),
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    account: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    dialog: false,
    textDialog: 'Tem certeza que deseja atualizar as configurações de pontos?',
    pointsTip: 'Controla o processamento interno de pontos na atualização diária.',
    btnsTip: 'Controla se o botão está disponível na home.',
    btns: {
      points: false
    },
    points: {
      active: false,
      rate: 1,
      duration: {
        scale: 'YEAR',
        amount: 1
      },
      start_date: '',
      end_date: '',
      last_points_computation_at: ''
    }
  }),
  computed: {
    pointsDurationScale() {
      return [
        { text: 'Ano', value: 'YEAR' },
        { text: 'Mês', value: 'MONTH' }
      ]
    }
  },
  apollo: {
    configuration: {
      query: QUERY_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      update({ configuration }) {
        this.points.active = Boolean(this.account?.channels?.app?.points?.active)
        this.points.rate = configuration.points?.rate || 0.0
        this.points.start_date = configuration.points?.start_date || ''
        this.points.end_date = configuration.points?.end_date || ''
        this.points.last_points_computation_at = configuration.points?.last_points_computation_at || '-'
        this.points.duration.scale = configuration.points?.duration?.scale || 'YEAR'
        this.points.duration.amount = configuration.points?.duration?.amount || 1
      }
    },
    appHomeButtons: {
      query: QUERY_HOME_BUTTONS,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      update({ appHomeButtons }) {
        this.btns.points = appHomeButtons.some(button => button.type === 'INTERNAL' && button.link === 'Points')
      }
    }
  },
  methods: {
    async updatePoints() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_POINTS,
          variables: {
            account_id: this.accountId,
            active: this.points.active,
            home_button: this.btns.points,
            rate: parseFloat(this.points.rate),
            scale: this.points.duration.scale,
            amount: parseInt(this.points.duration.amount),
            start_date: this.points.start_date,
            end_date: this.points.end_date
          }
        })
        this.$snackbar({ message: 'Pontos atualizados com sucesso', snackbarColor: '#2E7D32' })
        this.closeDialog()
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao atualizar pontos', snackbarColor: '#F44336' })
      }
      this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
      this.$apollo.queries.configuration.refetch()
      this.$apollo.queries.appHomeButtons.refetch()
    }
  }
}
</script>
